import { defer, type LoaderArgs } from '@shopify/remix-oxygen';
import { Suspense } from 'react';
import { Await, useLoaderData } from '@remix-run/react';
import {
  BottomSlides,
  ConnectingShelving,
  NewArrivals,
  PromotionCarousel,
  RecommendForYou,
  BlogSlides
} from '~/components';
import {
  MEDIA_FRAGMENT,
  PRODUCT_CARD_FRAGMENT,
  MEDIAIMAGE_FRAGMENT,
} from '~/data/fragments';
import { getHeroPlaceholder } from '~/lib/placeholders';
import type {
  CollectionConnection,
  Metafield,
  ProductConnection,
  MetafieldReferenceConnection,
  MetaobjectField,
  Metaobject,
  Collection as CollectionType,
  Blog
} from '@shopify/hydrogen/storefront-api-types';
import { AnalyticsPageType } from '@shopify/hydrogen';

interface HomeSeoData {
  shop: {
    name: string;
    description: string;
  };
}
interface fieldType {
  key: string;
  value: string;
  type: string;
  references: MetafieldReferenceConnection;
}
interface HomePageSlides {
  id: string;
  type: string;
  handle: string;
  fields: MetaobjectField;
}

export interface CollectionHero {
  byline: Metafield;
  cta: Metafield;
  handle: string;
  heading: Metafield;
  height?: 'full';
  loading?: 'eager' | 'lazy';
  spread: Metafield;
  spreadSecondary: Metafield;
  top?: boolean;
}

const BLOG_HANDLE = 'News';

export async function loader({ params, context }: LoaderArgs) {
  const { language, country } = context.storefront.i18n;

  if (
    params.lang &&
    params.lang.toLowerCase() !== `${language}-${country}`.toLowerCase()
  ) {
    // If the lang URL param is defined, yet we still are on `EN-US`
    // the the lang param must be invalid, send to the 404 page
    throw new Response(null, { status: 404 });
  }

  const { shop, hero } = await context.storefront.query<{
    hero: CollectionHero;
    shop: HomeSeoData;
  }>(HOMEPAGE_SEO_QUERY, {
    variables: { handle: 'freestyle' },
  });

  return defer({
    shop,
    primaryHero: hero,
    homePageSlides: context.storefront.query<{
      metaobject: Metaobject;
    }>(METAOBJECT_HOMEPAGE_SLIDES_QUERY, {
      variables: {
        handle: {
          handle: 'home-page-dxtvr80t',
          type: 'home_page',
        },
        country,
        language,
      },
    }),
    // These different queries are separated to illustrate how 3rd party content
    // fetching can be optimized for both above and below the fold.
    featuredProducts: context.storefront.query<{
      products: ProductConnection;
    }>(HOMEPAGE_FEATURED_PRODUCTS_QUERY, {
      variables: {
        /**
         * Country and language properties are automatically injected
         * into all queries. Passing them is unnecessary unless you
         * want to override them from the following default:
         */
        country,
        language,
      },
    }),
    secondaryHero: context.storefront.query<{ hero: CollectionHero }>(
      COLLECTION_HERO_QUERY,
      {
        variables: {
          handle: 'backcountry',
          country,
          language,
        },
      },
    ),
    featuredCollections: context.storefront.query<{
      collections: CollectionConnection;
    }>(FEATURED_COLLECTIONS_QUERY, {
      variables: {
        country,
        language,
      },
    }),
    bestSellers: context.storefront.query<{
      collection: CollectionType;
    }>(COLLECTION_QUERY, {
      variables: {
        handle: "our-best-sellers",
        pageBy: 8,
        country: context.storefront.i18n.country,
        language: context.storefront.i18n.language,
      },
    }),
    blogs: context.storefront.query<{
      blog: Blog;
    }>(BLOGS_QUERY, {
      variables: {
        blogHandle: BLOG_HANDLE,
        pageBy: 8,
        language,
      },
    }),
    analytics: {
      pageType: AnalyticsPageType.home,
    },
  });
}

export default function Homepage() {
  const {
    primaryHero,
    secondaryHero,
    blogs,
    featuredCollections,
    featuredProducts,
    homePageSlides,
    bestSellers
  } = useLoaderData<typeof loader>();

  // TODO: skeletons vs placeholders
  const skeletons = getHeroPlaceholder([{}, {}, {}]);

  // TODO: analytics
  // useServerAnalytics({
  //   shopify: {
  //     pageType: ShopifyAnalyticsConstants.pageType.home,
  //   },
  // });
  return (
    <>
      <Suspense>
        <Await resolve={homePageSlides}>
          {({ metaobject }) => {
            return <PromotionCarousel data={metaobject} />;
          }}
        </Await>
      </Suspense>

      {/* <NewArrivals /> */}
      <Suspense>
        <Await resolve={bestSellers}>
          {
            ({ collection }) => (
              <RecommendForYou
                collection={collection}
              />
            )
          }
          {/*  */}
        </Await>
      </Suspense>


      <Suspense>
        <Await resolve={homePageSlides}>
          {({ metaobject }) => {
            return (
              <ConnectingShelving
                data={metaobject}
                title="Connecting Shelving"
                handler="imgs_grid"
                linkHandler="connecting-shelving-special"
              />
            );
          }}
        </Await>
      </Suspense>
      {/* <ConnectingShelving /> */}
      <Suspense>
        <Await resolve={homePageSlides}>
          {({ metaobject }) => {
            return (
              <ConnectingShelving
                data={metaobject}
                title="Cabinet"
                handler="cabinet"
                linkHandler="cabinet-special"
              />
            );
          }}
        </Await>
      </Suspense>
      {/* <ConnectingShelving /> */}
      <Suspense>
        <Await resolve={homePageSlides}>
          {({ metaobject }) => {
            return <BottomSlides data={metaobject} />;
          }}
        </Await>
      </Suspense>
      {/* <BottomSlides /> */}
      <Suspense>
        <Await resolve={blogs}>
          {(blogs) => {
            return <BlogSlides data={blogs.blog} />;
          }}
        </Await>
      </Suspense>
    </>
  );
}

const COLLECTION_CONTENT_FRAGMENT = `#graphql
  ${MEDIA_FRAGMENT}
  fragment CollectionContent on Collection {
    id
    handle
    title
    descriptionHtml
    heading: metafield(namespace: "hero", key: "title") {
      value
    }
    byline: metafield(namespace: "hero", key: "byline") {
      value
    }
    cta: metafield(namespace: "hero", key: "cta") {
      value
    }
    spread: metafield(namespace: "hero", key: "spread") {
      reference {
        ...Media
      }
    }
    spreadSecondary: metafield(namespace: "hero", key: "spread_secondary") {
      reference {
        ...Media
      }
    }
  }
`;

const HOMEPAGE_SEO_QUERY = `#graphql
  ${COLLECTION_CONTENT_FRAGMENT}
  query collectionContent($handle: String, $country: CountryCode, $language: LanguageCode)
  @inContext(country: $country, language: $language) {
    hero: collection(handle: $handle) {
      ...CollectionContent
    }
    shop {
      name
      description
    }
  }
`;

const COLLECTION_HERO_QUERY = `#graphql
  ${COLLECTION_CONTENT_FRAGMENT}
  query collectionContent($handle: String, $country: CountryCode, $language: LanguageCode)
  @inContext(country: $country, language: $language) {
    hero: collection(handle: $handle) {
      ...CollectionContent
    }
  }
`;

// @see: https://shopify.dev/api/storefront/latest/queries/products
export const HOMEPAGE_FEATURED_PRODUCTS_QUERY = `#graphql
  ${PRODUCT_CARD_FRAGMENT}
  query homepageFeaturedProducts($country: CountryCode, $language: LanguageCode)
  @inContext(country: $country, language: $language) {
    products(first: 8) {
      nodes {
        ...ProductCard
      }
    }
  }
`;

// @see: https://shopify.dev/api/storefront/latest/queries/collections
export const FEATURED_COLLECTIONS_QUERY = `#graphql
  query homepageFeaturedCollections($country: CountryCode, $language: LanguageCode)
  @inContext(country: $country, language: $language) {
    collections(
      first: 4,
      sortKey: UPDATED_AT
    ) {
      nodes {
        id
        title
        handle
        image {
          altText
          width
          height
          url
        }
      }
    }
  }
`;

export const METAOBJECT_HOMEPAGE_SLIDES_QUERY = `#graphql
  ${MEDIAIMAGE_FRAGMENT}
  query homepageSlides($handle: MetaobjectHandleInput, $country: CountryCode, $language: LanguageCode)
  @inContext(country: $country, language: $language) {
    metaobject(
      handle: $handle
    ) {
      id 
      type
      updatedAt
      handle
      fields {
        key
        value
        type
        references(first: 10) {
          nodes {
            ...MediaImage
          }
        }
      }
    }
  }
`;


const COLLECTION_QUERY = `#graphql
  ${PRODUCT_CARD_FRAGMENT}
  query CollectionDetails(
    $handle: String!
    $country: CountryCode
    $language: LanguageCode
    $pageBy: Int!
  ) @inContext(country: $country, language: $language) {
    collection(handle: $handle) {
      id
      handle
      title
      description
      seo {
        description
        title
      }
      image {
        id
        url
        width
        height
        altText
      }
      products(
        first: $pageBy,
      ) {
        filters {
          id
          label
          type
          values {
            id
            label
            count
            input
          }
        }
        nodes {
          ...ProductCard
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
`;

const BLOGS_QUERY = `#graphql
query Blog(
  $language: LanguageCode
  $blogHandle: String!
  $pageBy: Int!
  $cursor: String
) @inContext(language: $language) {
  blog(handle: $blogHandle) {
    articles(first: $pageBy, after: $cursor, reverse: true) {
      edges {
        node {
          author: authorV2 {
            name
          }
          contentHtml
          handle
          id
          image {
            id
            altText
            url
            width
            height
          }
          publishedAt
          title
        }
      }
    }
  }
}
`;